<template>
  <div>
    <v-form ref="loginForm" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">Ingresar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="space-around">
              <v-col cols="5">
                <v-img
                  class="mx-2"
                  src="img/indosur.png"
                  max-height="100"
                  max-width="100"
                  contain
                ></v-img>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :rules="[(v) => !!v || 'Ingrese su nombre de usuario']"
                  v-model="username"
                  label="Nombre de usuario"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :rules="[(v) => !!v || 'Ingrese su contraseña']"
                  v-model="password"
                  label="Contraseña"
                  type="password"
                  required
                  @keyup.enter="submitLogin"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="remember_me"
                  label="Recordarme"
                  color="teal"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="isDialog" color="teal" text @click="$emit('dialog')">
            Cerrar
          </v-btn>
          <v-btn
            text
            color="teal"
            :loading="loading"
            :disabled="loading"
            @click="submitLogin"
          >
            Ingresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      :timeout="5000"
      rounded
      bottom
      v-model="error.status"
      color="red"
    >
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon rounded v-bind="attrs" @click="error.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["isDialog"],

  name: "LoginComponent",

  data: () => ({
    username: "",
    password: "",
    remember_me: true,

    state: false,
    loading: false,
    error: {
      status: false,
      message: "",
    },
    valid: true,
  }),

  methods: {
    ...mapActions("session", ["login"]),

    async submitLogin() {
      this.loading = true;

      this.$refs.loginForm.validate();

      if (this.valid) {
        await this.login({
          username: this.username,
          password: this.password,
          remember_me: this.remember_me,
        }).then((data) => {
          if (data.message) {
            this.error = { status: true, message: data.message };
          } else if (data.userType === "Admin") {
            this.$router.push("/admin/receptions");
          } else if (data.userType === "Pharmacy") {
            this.$router.push("/pharmacy/recipes");
          }
          this.status = false;
        });
        this.loading = false;
      }
    },
  },
};
</script>
